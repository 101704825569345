<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        (a) Calculate the pH of a solution that is prepared by dissolving
        <number-value :value="lacticAcid" unit="\text{g}" />
        <chemical-latex content="(CH3CHOHCOOH)" />
        and
        <number-value :value="sodiumLactate" unit="\text{g}" />
        of sodium lactate
        <chemical-latex content="(CH3CHOHCOONa)" />
        in water and diluting to
        <chemical-latex content="1.00 L\text{.}" />
      </p>
      <calculation-input
        v-model="inputs.studentAnswerA"
        append-text="$\text{}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        (b) Calculate the pH after adding
        <chemical-latex content="0.020 \text{mole}" />
        of solid
        <chemical-latex content="NaOH" />
        to
        <chemical-latex content="1.00 L" />
        of the buffer solution in part (a)
      </p>
      <calculation-input
        v-model="inputs.studentAnswerB"
        append-text="$\text{}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        (c) Calculate the pH after adding
        <chemical-latex content="0.020 mole" />
        of
        <chemical-latex content="HCl" />
        to
        <chemical-latex content="1.00 L" />
        of the buffer solution in part (a).
      </p>
      <calculation-input
        v-model="inputs.studentAnswerC"
        append-text="$\text{}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A5_Q3',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerA: null,
        studentAnswerB: null,
        studentAnswerC: null,
      },
    };
  },
  computed: {
    lacticAcid() {
      return this.taskState.getValueBySymbol('lacticAcid').content;
    },
    sodiumLactate() {
      return this.taskState.getValueBySymbol('sodiumLactate').content;
    },
  },
};
</script>
